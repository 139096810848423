@import "../rgbstyle/rgbstyle-mixins";

.el-texto {
    padding: 20px 0 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include tablet {
        padding: 40px 0 30px;
    }
    @include desktop-hd {
        padding: 60px 0 30px;
    }

    .img {
        //background: rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
        @include mq-min(1920) {
            margin-bottom: valor-fluido(20, 1920);
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            height: auto;
        }

        span {
            display: block;
            clear: both;
            padding: 10px;
            text-align: center;
            word-break: break-word;
            font-size: 12px;
            @include mq-min(1920) {
                padding: valor-fluido(10, 1920);
                font-size: valor-fluido(12, 1920);
            }
        }
    }
}